<template>  
    <v-card>
      <v-toolbar color="danger" dense>
        <v-toolbar-title>Confirmation de suppression</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <span>Êtes-vous sûr(e) de vouloir supprimer cette configuration ?</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal" >Annuler</v-btn>
        <v-btn color="error" @click="deleteDiffusion" >Confirmer</v-btn>
      </v-card-actions>
    </v-card>
</template>
<script>

import DiffusionService from "@/Services/SupportVision/DiffusionService"


export default {
  name: 'Delete',
  data: () => ({
    alertDelete: false,
  }),
  computed: {
    selectedDiffusionId() {
      return this.$store.state.selectedDiffusionId;
    },
  },  
  methods: {
    closeModal() {
      this.alertDelete = false;
      this.$emit('close-modal');
    },
    async deleteDiffusion() {
      await DiffusionService.removeDiffusion(this.selectedDiffusionId);
      this.closeModal();
      this.$emit('diffusion-deleted');
    },
  }
}

</script>
